/* eslint-disable vue/one-component-per-file */

import { ColDef } from 'ag-grid-enterprise';
import { Decimal } from 'decimal.js';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import OpenLoansTableAggregateActions from '@/modules/open-loans/components/OpenLoansTableAggregateActions.vue';
import { MultipleLoanAction } from '@/modules/open-loans/types/open-loans';
import { alignCenter, alignLeft, alignRight, component } from './utils';
import { h } from 'vue';
import * as cols from '@/modules/common/components/ag-table/columns/common';
import { AggregatedLoanByCounterpartyItem, AggregatedLoanBySecurityItem } from '@/utils/api/loans';
export { side } from './common';

export function ticker(): ColDef {
  return {
    field: 'ticker',
    colId: 'ticker',
    headerName: 'Ticker',
    ...alignLeft(),
  };
}

export function cusip(): ColDef {
  return {
    field: 'cusip',
    colId: 'cusip',
    headerName: 'CUSIP',
    ...alignLeft(),
  };
}

// @TODO: we should stop using "counterpartyDisplay" and use "counterparty" instead
// because it's tightly coupled with the API response
export function counterpartyDisplay(): ColDef {
  return {
    field: 'counterpartyDisplay',
    colId: 'counterpartyDisplay',
    headerName: 'Counterparty',
    ...alignLeft(),
  };
}

export const loansCount = (): ColDef => ({
  ...cols.quantity({
    field: 'loansCount',
    headerName: '#Loans',
  }),
});

export const counterpartyCount = (): ColDef => ({
  ...cols.quantity({
    field: 'counterpartyCount',
    headerName: '#Counterparties',
  }),
});

export const securityCount = (): ColDef => ({
  ...cols.quantity({
    field: 'securityCount',
    headerName: '#Securities',
  }),
});

export const totalOpenQty = (): ColDef => ({
  ...cols.quantity({
    field: 'totalOpenQty',
    headerName: 'Total Open',
  }),
});

const avgRateAdapter = component<{
  rate: number | string | Decimal | null;
}>((props) => () => h(RateOutput, { props }));

export function avgRate(): ColDef {
  return {
    field: 'avgRate',
    colId: 'avgRate',
    headerName: 'Avg Rate',
    cellRendererSelector: (params) =>
      avgRateAdapter({
        rate: params.value,
      }),
    cellDataType: 'text',
    ...alignRight(),
  };
}

export const totalValue = (): ColDef => ({
  ...cols.price({
    field: 'totalValue',
    headerName: 'Total Value',
  }),
});

export const totalRecallQty = (): ColDef => ({
  ...cols.quantity({
    field: 'totalRecallQty',
    headerName: 'Total Recalled',
  }),
});

const actionsAdapter = component<{
  item: AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem;
  openDialog: (
    action: MultipleLoanAction,
    item: AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem
  ) => void;
}>(
  (props) => () =>
    h(OpenLoansTableAggregateActions, {
      props: { item: props.item },
      on: { 'open-dialog': props.openDialog },
    })
);

export function actions({
  openDialog,
}: {
  openDialog: (
    action: MultipleLoanAction,
    item: AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem
  ) => void;
}): ColDef {
  return {
    field: 'actions',
    colId: 'actions',
    headerName: 'Actions',
    sortable: false,
    cellRendererSelector: (params) =>
      actionsAdapter({
        item: params.data,
        openDialog,
      }),
    pinned: 'right',
    lockVisible: true,
    width: 150,
    maxWidth: 150,
    suppressColumnsToolPanel: true,
    ...alignCenter(),
  };
}
