import i18n from '@/localisation/i18n';
import { VBtn, VIcon } from 'vuetify/lib';
import { alignCenter, alignLeft, component } from './utils';
import { h } from 'vue';
import { UserAccount } from '@/modules/user-accounts/types/user-accounts';
import TraderUsersRiskLimitIcons from '@/modules/trader-admin/components/TraderUsersRiskLimitIcons.vue';
import TraderUsersActions from '@/modules/trader-admin/components/TraderUsersActions.vue';
import { ColDef } from 'ag-grid-enterprise';
import { formatDate } from '@/utils/helpers/dates';
import { formatDuration, intervalToDuration, isAfter, parseISO, subMilliseconds } from 'date-fns';
import { HEARTBEAT_INTERVAL } from '@/store/store-auth';

export function name(): ColDef {
  return {
    field: 'name',
    colId: 'name',
    headerName: 'User',
    ...alignLeft(),
  };
}

export function company(): ColDef {
  return {
    field: 'companyName',
    colId: 'companyName',
    headerName: 'Company Name',
    ...alignLeft(),
  };
}

export function email(): ColDef {
  return {
    field: 'emailAddress',
    colId: 'emailAddress',
    headerName: 'Email',
    ...alignLeft(),
  };
}

const userRoleAdapter = component<{
  item: UserAccount;
}>((props) => () => {
  return h('div', [
    h('span', i18n.tc(props.item.roles[0])),
    props.item.accountStatus === 'pending-approval-for-roles'
      ? h('span', ` ⇢ ${i18n.tc(props.item.rolesPendingApproval![0])}`)
      : undefined,
  ]);
});

export function role(): ColDef {
  return {
    field: 'userRole',
    colId: 'userRole',
    headerName: 'Role',
    cellRendererSelector: (params) => userRoleAdapter({ item: params.data }),
    ...alignLeft(),
  };
}

export function traderAccountStatus(): ColDef {
  return {
    field: 'accountStatus',
    colId: 'accountStatus',
    headerName: 'Account Status',
    valueFormatter: (params) => i18n.tc(`userAccounts.status.${params.value}`),
    ...alignLeft(),
  };
}

const brokerAccountStatusAdapter = component<{
  item: UserAccount;
  approve: (item: UserAccount) => void;
  hasBrokerComplianceRepRole: boolean;
}>((props) => () => {
  return h(
    VBtn,
    {
      props: { color: 'primary', xSmall: true },
      on: {
        click: () => props.approve(props.item),
      },
    },
    props.item.accountStatus === 'pending-approval'
      ? i18n.tc('adminApproveUser')
      : props.item.accountStatus === 'pending-approval-for-roles'
        ? i18n.tc('adminApproveRoles')
        : undefined
  );
});

export function brokerAccountStatus({
  approve,
  hasBrokerComplianceRepRole,
}: {
  approve: (item: UserAccount) => void;
  hasBrokerComplianceRepRole: boolean;
}): ColDef {
  return {
    field: 'accountStatus',
    colId: 'accountStatus',
    headerName: 'Account Status',
    // we need to force it because autosized on first render is sometimes too small
    // i.e. when it starts with no button and we create a user, button doesn't fit
    minWidth: 200,
    valueFormatter: (params) => i18n.tc(`userAccounts.status.${params.value}`),
    cellRendererSelector: (params) => {
      if (
        hasBrokerComplianceRepRole &&
        ['pending-approval', 'pending-approval-for-roles'].includes(params.data.accountStatus)
      ) {
        return brokerAccountStatusAdapter({
          item: params.data as UserAccount,
          approve,
          hasBrokerComplianceRepRole,
        });
      }
    },
    ...alignLeft(),
  };
}

const tfaIsEnabledAdapter = component<{
  item: UserAccount;
}>((props) => () => {
  return h(VIcon, [props.item.tfaIsEnabled ? 'mdi-two-factor-authentication' : undefined]);
});

export function tfa(): ColDef {
  return {
    field: 'tfaIsEnabled',
    colId: 'tfaIsEnabled',
    headerName: '2FA',
    cellRendererSelector: (params) => tfaIsEnabledAdapter({ item: params.data }),
    ...alignLeft(),
  };
}

const riskLimitsAdapter = component<{
  item: UserAccount;
}>((props) => () => {
  return h(TraderUsersRiskLimitIcons, { props: { item: props.item } });
});

export function riskLimits(): ColDef {
  return {
    field: 'riskLimits',
    colId: 'riskLimits',
    headerName: 'Risk Limits',
    cellRendererSelector: (params) => riskLimitsAdapter({ item: params.data }),
    ...alignLeft(),
  };
}

const actionsAdapter = component<{
  item: UserAccount;
  deleteUser: (item: UserAccount) => void;
  disableUser: (item: UserAccount) => void;
  editUser: (item: UserAccount) => void;
  enableUser: (item: UserAccount) => void;
}>((props) => () => {
  return h(TraderUsersActions, {
    props: {
      item: props.item,
    },
    on: {
      'delete-user': props.deleteUser,
      'disable-user': props.disableUser,
      'edit-user': props.editUser,
      'enable-user': props.enableUser,
    },
  });
});

export function actions({
  deleteUser,
  disableUser,
  editUser,
  enableUser,
}: {
  deleteUser: (item: UserAccount) => void;
  disableUser: (item: UserAccount) => void;
  editUser: (item: UserAccount) => void;
  enableUser: (item: UserAccount) => void;
}): ColDef {
  return {
    field: 'actions',
    colId: 'actions',
    headerName: 'Actions',
    cellRendererSelector: (params) =>
      actionsAdapter({
        item: params.data as UserAccount,
        deleteUser,
        disableUser,
        editUser,
        enableUser,
      }),
    pinned: 'right',
    width: 150,
    lockVisible: true,
    ...alignCenter(),
  };
}

export function longCompanyName(): ColDef {
  return {
    field: 'companyName',
    colId: 'companyName',
    headerName: 'Company Name',
    valueFormatter: (params) =>
      `${params.data.companyName} ${
        params.data.companyDisplayBoxID !== null ? ' (' + params.data.companyDisplayBoxID + ')' : ''
      }`,
    ...alignLeft(),
  };
}

export function tradingPermissions(): ColDef {
  return {
    field: 'tradingPermissions',
    colId: 'tradingPermissions',
    headerName: 'Trading Permissions',
    valueFormatter: (params) =>
      params.data.tradingPermissions !== null
        ? i18n.tc(`tradingPermissions.options.${params.value}`)
        : i18n.tc(`tradingPermissions.inherit`),
    ...alignLeft(),
  };
}

export function lastLogin(): ColDef {
  return {
    field: 'lastLoginAt',
    colId: 'lastLoginAt',
    headerName: 'Last Login',
    valueFormatter: (params) => formatDate(params.value, 'hh:mm:ss MM/dd/yy'),
    ...alignLeft(),
  };
}

export function lastActive(): ColDef {
  return {
    field: 'lastActive',
    colId: 'lastActive',
    headerName: 'Last Active',
    valueFormatter: (params) => {
      if (params.value === null) {
        return '';
      }

      const now = new Date();
      const nowThreshold = subMilliseconds(now, HEARTBEAT_INTERVAL);

      if (isAfter(parseISO(params.value), nowThreshold)) {
        return 'now';
      } else {
        return formatDate(params.value, 'hh:mm:ss MM/dd/yy');
      }
    },
    ...alignLeft(),
  };
}

export function totalActiveTime(): ColDef {
  return {
    field: 'totalActiveMinutes',
    colId: 'totalActiveMinutes',
    headerName: 'Total Active Time',
    valueFormatter: (params) => {
      const duration = intervalToDuration({ start: 0, end: params.value * 60 * 1000 });
      return formatDuration(duration);
    },
    ...alignLeft(),
  };
}
