import { ColDef, ValueFormatterParams } from 'ag-grid-enterprise';
import { alignColumn, alignLeft, alignRight, component } from './utils';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import Decimal from 'decimal.js';
import { formatDate } from '@/utils/helpers/dates';
import { Benchmark } from '@/utils/api/loans';
import { h } from 'vue';
import FormatSide from '@/modules/common/components/format-side/FormatSide.vue';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';

export function prettyPrice(value: Decimal): string {
  return getPriceAsString(value, PRICE_PRECISION);
}

interface ColConfig {
  field: string;
  headerName: string;
  align?: 'left' | 'right' | 'center';
}

export function checkbox(): ColDef {
  return {
    field: '',
    colId: 'checkbox',
    headerCheckboxSelection: true,
    // it would be great to be able to use
    // headerCheckboxSelectionCurrentPageOnly: true
    // but it's not supported with "serverSide" row model
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    resizable: false,
    pinned: 'left',
    lockVisible: true,
    sortable: true,
    maxWidth: 64,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    suppressColumnsToolPanel: true,
  };
}

// format number with thousands separator and 2 decimal places aligining right
export function quantity({ field, headerName, align = 'right' }: ColConfig): ColDef {
  return {
    field,
    colId: field,
    headerName,
    valueFormatter: (params: ValueFormatterParams) => formatPrettyNumber(params.value),
    // for very speficic cases we want to align left (like top/depth of book views that mirror sides)
    ...alignColumn({ align }),
  };
}

export function price({ field, headerName, align = 'right' }: ColConfig): ColDef {
  return {
    field,
    colId: field,
    headerName,
    valueFormatter: (params: ValueFormatterParams) => `$${prettyPrice(params.value)}`,
    ...alignColumn({ align }),
  };
}

const timestampAdapter = component<{ date: string; time: string }>(
  (props) => () =>
    h('div', [h('span', props.date), h('span', { class: 'ml-1 font-weight-light' }, props.time)])
);

export function timestamp({ field, headerName, align = 'left' }: ColConfig): ColDef {
  return {
    field,
    colId: field,
    headerName,
    cellRendererSelector: (params) =>
      timestampAdapter({
        date: formatDate(params.value, 'MMM dd'),
        time: formatDate(params.value, 'hh:mm a'),
      }),
    ...alignColumn({ align }),
  };
}

export function date({ field, headerName, align = 'left' }: ColConfig): ColDef {
  return {
    field,
    colId: field,
    headerName,
    valueFormatter: (params: ValueFormatterParams) => formatDate(params.value, 'MM/dd/yy'),
    ...alignColumn({ align }),
  };
}

export function cusip(): ColDef {
  return {
    field: 'equity.cusip',
    colId: 'equity.cusip',
    headerName: 'CUSIP',
    ...alignLeft(),
  };
}

export const rateAdapter = component<{
  rate: null | number | string | Decimal;
  rateModifier: undefined | Benchmark;
  precision: undefined | number;
}>((props) => () => (props.rate === null ? h('span', undefined, '–') : h(RateOutput, { props })));

export function rate(): ColDef {
  return {
    field: 'rate',
    colId: 'rate',
    headerName: 'Rate',
    cellRendererSelector: (params) =>
      rateAdapter({
        rate: params.value,
        rateModifier: params.data.rateModifier,
        precision: undefined,
      }),
    cellDataType: 'text',
    ...alignRight({ hasPostfix: true }),
  };
}

export const formatSideAdapter = component<{ side: 'BORROWER' | 'LENDER' }>(
  (props) => () => h(FormatSide, { props })
);

export function side(): ColDef {
  return {
    field: 'side',
    colId: 'side',
    headerName: 'Side',
    cellRendererSelector: (params) =>
      formatSideAdapter({
        side: params.value,
      }),
    ...alignLeft(),
  };
}

export function ticker(): ColDef {
  return {
    field: 'equity.ticker',
    colId: 'equity.ticker',
    headerName: 'Ticker',
    ...alignLeft(),
  };
}

const settlementOptions: Array<{ value: string; text: string }> = [
  { value: 'NSCC', text: 'NSCC' },
  { value: 'BILATERAL', text: 'Bilateral' },
  { value: 'OCC', text: 'OCC' },
];

export function settlementTypeLabel(settlType: string): string {
  const item = settlementOptions.find((i) => i.value === settlType);
  return item ? item.text : '–';
}

const settlementTypeAdapter = component<{ settlType: string }>(
  (props) => () => h('span', settlementTypeLabel(props.settlType))
);

// settlementOptions[props.settlementType].

export function settlementType(): ColDef {
  return {
    field: 'settlementType',
    colId: 'settlementType',
    headerName: 'Sett Type',
    cellRendererSelector: (params) =>
      settlementTypeAdapter({
        settlType: params.value,
      }),
    ...alignLeft(),
  };
}
