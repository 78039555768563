<template>
  <v-chip
    v-if="loan.status === 'OPEN' && loan.pendingReturnQuantity > 0"
    color="warning"
    small
    @click="handleClick"
  >
    Returning / <pretty-number :value="loan.pendingReturnQuantity" />
  </v-chip>

  <v-chip
    v-else-if="loan.pendingBuyInQuantity > 0"
    color="orange darken-2"
    small
    @click="handleClick"
  >
    Buying-in / <pretty-number :value="loan.pendingBuyInQuantity" />
  </v-chip>

  <v-chip v-else :color="getStatusColor(loan.status)" small @click="handleClick">
    {{ $t(`loan-status-${loan.status}`) }}
  </v-chip>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { OpenLoan, statusColor } from '@/utils/api/loans';

@Component({
  props: {
    loan: Object as PropType<OpenLoan>,
    click: Function as PropType<(loan: OpenLoan) => void>,
  },
})
export default class LoanStatusChip extends Vue {
  protected readonly loan!: OpenLoan;
  protected readonly click!: (loan: OpenLoan) => void;

  protected getStatusColor = statusColor;

  protected handleClick(): void {
    if (this.click) {
      this.click(this.loan);
    } else {
      this.$emit('click', this.loan);
    }
  }
}
</script>
<style lang="scss" scoped>
.v-chip {
  width: 4rem;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
}
</style>
