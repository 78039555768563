import i18n from '@/localisation/i18n';
import { SidebarGroupChildren, SidebarItem } from '@/modules/sidebar/types/sidebar';
import { Role } from '@/utils/helpers/permissions';
import { ClientConfig, UXConfig } from '@/utils/helpers/rest';
import { TradingPermission, hasOneOfTradingPermission } from '@/utils/helpers/trading-permissions';
import { LoginState } from '@/store/store';

/*
 * 1) Hydrate isVisible
 * 2) Filter by isVisible and user role
 * 3) Return the items for the Sidebar
 */
export function getSidebarItems(
  clientCfg: ClientConfig,
  uxCfg: UXConfig,
  ls: LoginState
): SidebarItem[] {
  const isOrderbookEnabled =
    uxCfg.orderbookEnabledOverride !== null
      ? uxCfg.orderbookEnabledOverride
      : clientCfg.orderbookEnabled;

  const items: SidebarItem[] = [
    // Marketplace
    {
      kind: 'group',
      key: 'marketplace',
      children: [
        { to: 'marketplace.orderbook', title: i18n.t('sidebar.items.marketplaceOrderbook') },
        {
          to: 'marketplace.symbol-overview',
          title: i18n.t('sidebar.items.marketplaceSymbolOverview'),
        },
        { to: 'marketplace.orders', title: i18n.t('sidebar.items.marketplaceOrders') },
      ],
      title: i18n.t('sidebar.items.marketplace'),
      icon: 'mdi-chart-line-variant',
      role: Role.TraderViewer,
      isVisible:
        isOrderbookEnabled &&
        hasOneOfTradingPermission(ls, TradingPermission.LenderOrBorrowerPermissions) &&
        ls.user?.sponsorshipSide !== 'sponsored' &&
        !ls.user?.roles?.includes('ops-user'),
    },
    // Term Loans
    {
      kind: 'link',
      key: 'term-loans',
      to: 'termloans.contracts',
      title: i18n.t('sidebar.items.termLoans'),
      icon: 'mdi-swap-horizontal',
      role: Role.TraderViewer,
      isVisible:
        clientCfg.demoMode &&
        hasOneOfTradingPermission(ls, TradingPermission.LenderOrBorrowerPermissions) &&
        ls.user?.sponsorshipSide !== 'sponsored' &&
        !ls.user?.roles?.includes('ops-user'),
    },
    // Trader user
    {
      kind: 'link',
      key: 'notrading',
      to: 'notrading',
      title: i18n.t('sidebar.items.notrading'),
      icon: 'mdi-account-alert',
      role: Role.TraderViewer,
      isVisible: !hasOneOfTradingPermission(ls, TradingPermission.LenderOrBorrowerPermissions),
    },
    {
      kind: 'link',
      key: 'loans-explorer',
      to: 'loans.explorer',
      title: i18n.t('sidebar.items.loansExplorer'),
      // @TODO: find another suitable icon?
      icon: 'mdi-compass',
      role: Role.TraderViewer,
      isVisible:
        hasOneOfTradingPermission(ls, TradingPermission.LenderOrBorrowerPermissions) &&
        ls.user?.sponsorshipSide !== 'sponsored',
    },
    {
      kind: 'link',
      key: 'open-loans',
      to: 'loans.open',
      title: i18n.t('sidebar.items.openLoans'),
      icon: 'mdi-swap-horizontal',
      role: Role.TraderViewer,
      isVisible:
        hasOneOfTradingPermission(ls, TradingPermission.LenderOrBorrowerPermissions) &&
        ls.user?.sponsorshipSide !== 'sponsored',
    },
    {
      kind: 'link',
      key: 'sponsorship-loans',
      to: 'sponsorship.loans',
      title: i18n.t('sidebar.items.sponsoredLoans'),
      icon: 'mdi-subtitles',
      role: Role.TraderViewer,
      isVisible: ls.user?.sponsorshipSide !== null,
    },
    {
      kind: 'link',
      key: 'lender.supply',
      to: 'lender.supply',
      title: i18n.t('sidebar.items.lenderSupply'),
      icon: 'mdi-account-arrow-right-outline',
      role: Role.TraderViewer,
      isVisible:
        !isOrderbookEnabled &&
        hasOneOfTradingPermission(ls, TradingPermission.LenderPermissions) &&
        ls.user?.sponsorshipSide !== 'sponsored' &&
        !ls.user?.roles?.includes('ops-user'),
    },
    {
      kind: 'link',
      key: 'borrower.demand',
      to: 'borrower.demand',
      title: i18n.t('sidebar.items.borrowerDemand'),
      icon: 'mdi-account-arrow-left-outline',
      role: Role.TraderViewer,
      isVisible:
        !isOrderbookEnabled &&
        hasOneOfTradingPermission(ls, TradingPermission.BorrowerPermissions) &&
        ls.user?.sponsorshipSide !== 'sponsored' &&
        !ls.user?.roles?.includes('ops-user'),
    },
    {
      kind: 'link',
      key: 'borrower.locates',
      to: 'borrower.locates',
      title: i18n.t('sidebar.items.borrowerLocates'),
      icon: 'mdi-card-search-outline',
      role: Role.TraderViewer,
      isVisible:
        clientCfg.demoMode &&
        !isOrderbookEnabled &&
        hasOneOfTradingPermission(ls, TradingPermission.BorrowerPermissions) &&
        ls.user?.sponsorshipSide !== 'sponsored' &&
        !ls.user?.roles?.includes('ops-user'),
    },
    {
      kind: 'link',
      key: 'manual-loans',
      to: 'loans.manual',
      title: i18n.t('sidebar.items.manualLoans'),
      icon: 'mdi-handshake',
      role: Role.TraderViewer,
      isVisible:
        hasOneOfTradingPermission(ls, TradingPermission.LenderOrBorrowerPermissions) &&
        ls.user?.sponsorshipSide !== 'sponsored' &&
        !ls.user?.roles?.includes('ops-user'),
    },
    {
      kind: 'link',
      key: 'pre-established-loans',
      to: 'loans.pre-established',
      title: i18n.t('sidebar.items.preEstablished'),
      icon: 'mdi-bank-transfer',
      role: Role.TraderViewer,
      isVisible:
        hasOneOfTradingPermission(ls, TradingPermission.LenderOrBorrowerPermissions) &&
        ls.user?.sponsorshipSide !== 'sponsored' &&
        !ls.user?.roles?.includes('ops-user'),
    },
    {
      kind: 'group',
      key: 'sec-lending',
      children: [
        { to: 'sec-lending.active', title: i18n.t('sidebar.items.secLendingActive') },
        { to: 'sec-lending.history', title: i18n.t('sidebar.items.secLendingHistory') },
      ],
      title: i18n.t('sidebar.items.secLending'),
      icon: 'mdi-signature-freehand',
      role: Role.TraderViewer,
      isVisible:
        clientCfg.createAuctionsEnabled &&
        uxCfg.hasSLAuction &&
        hasOneOfTradingPermission(ls, TradingPermission.LenderOrBorrowerPermissions) &&
        ls.user?.sponsorshipSide !== 'sponsored',
    },

    {
      kind: 'group',
      key: 'analytics',
      children: [
        {
          to: 'analytics.dashboard',
          title: i18n.t('sidebar.items.dashboard'),
          isVisible: true,
        },
        {
          to: 'analytics.varCalculator',
          title: i18n.t('sidebar.items.varCalculator'),
          isVisible: clientCfg.varCalcEnabled,
        },
        {
          to: 'analytics.trading',
          title: i18n.t('sidebar.items.tradingAnalytics'),
          isVisible: clientCfg.seclendingAnalyticsEnabled,
        },
      ],
      title: i18n.t('sidebar.items.analytics'),
      icon: 'mdi-google-analytics',
      role: Role.TraderViewer,
      isVisible:
        ls.user?.sponsorshipSide !== 'sponsored' &&
        hasOneOfTradingPermission(ls, TradingPermission.LenderOrBorrowerPermissions),
    },
    {
      kind: 'link',
      key: 'reports',
      to: 'reports',
      title: i18n.t('sidebar.items.reports'),
      icon: 'mdi-chart-timeline',
      role: Role.TraderViewer,
      isVisible: hasOneOfTradingPermission(ls, TradingPermission.LenderOrBorrowerPermissions),
    },
    {
      kind: 'link',
      key: 'transactions',
      to: 'transactions',
      title: i18n.t('sidebar.items.reportingFacility'),
      icon: 'mdi-chart-tree',
      role: Role.TraderViewer,
      isVisible: clientCfg.finraTransactionsEnabled,
    },
    {
      kind: 'link',
      key: 'swaps',
      // route name must exist in routes.ts, or vue-router consoles a warning
      to: 'error-400',
      // route name doesn't matter since this item isDisabled (displayed only, no link)
      isDisabled: true,
      title: i18n.t('sidebar.items.swaps'),
      icon: 'mdi-swap-horizontal-variant',
      role: Role.TraderViewer,
      isVisible: clientCfg.demoMode,
    },
    {
      kind: 'link',
      key: 'trade-optimizer',
      // route name must exist in routes.ts, or vue-router consoles a warning
      to: 'error-400',
      // route name doesn't matter since this item isDisabled (displayed only, no link)
      isDisabled: true,
      title: i18n.t('sidebar.items.tradeOptimizer'),
      icon: 'mdi-shape-circle-plus',
      role: Role.TraderViewer,
      isVisible: clientCfg.demoMode,
    },
    {
      kind: 'link',
      key: 'basket-tool',
      // route name must exist in routes.ts, or vue-router consoles a warning
      to: 'error-400',
      // route name doesn't matter since this item isDisabled (displayed only, no link)
      isDisabled: true,
      title: i18n.t('sidebar.items.basketTool'),
      icon: 'mdi-basket',
      role: Role.TraderViewer,
      isVisible: clientCfg.demoMode,
    },

    // Trader admin
    {
      kind: 'group',
      key: 'trader-admin',
      children: [
        {
          to: 'trader-admin.users',
          title: i18n.t('sidebar.items.traderAdminUsers'),
        },
        {
          to: 'trader-admin.company-preferences',
          title: i18n.t('sidebar.items.traderAdminCompanyPreferences'),
        },
        {
          to: 'trader-admin.counterparty-credit',
          title: i18n.t('sidebar.items.traderAdminCounterpartyCredit'),
        },
        {
          to: 'trader-admin.uptime-statistics',
          title: i18n.t('sidebar.items.adminUptimeStatistics'),
        },
        {
          to: 'trader-admin.sso',
          title: i18n.t('sidebar.items.traderAdminSSO'),
        },
      ],
      title: i18n.t('sidebar.items.traderAdmin'),
      icon: 'mdi-account-details',
      role: Role.TraderAdmin,
      isVisible: true,
    },

    // Broker user
    {
      kind: 'group',
      key: 'broker-admin.accounts',
      children: [
        {
          to: 'broker-admin.accounts.users',
          title: i18n.t('sidebar.items.brokerAdminAccountsUsers'),
        },
        {
          to: 'broker-admin.accounts.companies',
          title: i18n.t('sidebar.items.brokerAdminAccountsCompanies'),
        },
        {
          to: 'broker-admin.accounts.profiles',
          title: i18n.t('sidebar.items.brokerAdminAccountsProfiles'),
        },
        {
          to: 'broker-admin.accounts.ipblacklist',
          title: i18n.t('sidebar.items.brokerAdminAccountsIpblacklist'),
        },
      ],
      title: i18n.t('sidebar.items.brokerAdminAccounts'),
      icon: 'mdi-account-details',
      role: Role.BrokerAdmin,
      isVisible: true,
    },
    {
      kind: 'link',
      key: 'broker-admin.tables.equities',
      to: 'broker-admin.tables.equities',
      title: i18n.t('sidebar.items.equities'),
      icon: 'mdi-database',
      role: Role.BrokerAdmin,
      isVisible: true,
    },
    {
      kind: 'link',
      key: 'broker-admin.kill-switch',
      to: 'broker-admin.kill-switch',
      title: i18n.t('sidebar.items.killSwitch'),
      icon: 'mdi-toggle-switch-off',
      role: Role.BrokerAdmin,
      isVisible: true,
    },
    {
      kind: 'link',
      key: 'broker-user.uptime-statistics',
      to: 'broker-user.uptime-statistics',
      title: i18n.t('sidebar.items.adminUptimeStatistics'),
      icon: 'mdi-chart-areaspline',
      role: Role.BrokerUser,
      isVisible: true,
    },
    {
      kind: 'link',
      key: 'broker-user.stats',
      to: 'broker-user.stats',
      title: i18n.t('sidebar.items.stats'),
      icon: 'mdi-chart-areaspline',
      role: Role.BrokerUser,
      isVisible: true,
    },
    {
      kind: 'group',
      key: 'broker-user.client-monitor',
      children: [
        {
          to: 'broker-user.client-monitor.lend-orders',
          title: i18n.t('sidebar.items.clientMonitorLendOrders'),
          isVisible: !isOrderbookEnabled,
        },
        {
          to: 'broker-user.client-monitor.borrow-orders',
          title: i18n.t('sidebar.items.clientMonitorBorrowOrders'),
          isVisible: !isOrderbookEnabled,
        },
        {
          to: 'broker-user.client-monitor.marketplace-orders',
          title: i18n.t('sidebar.items.clientMonitorMarketplaceOrders'),
          isVisible: isOrderbookEnabled,
        },
        {
          to: 'broker-user.client-monitor.marketplace-orderbook',
          title: i18n.t('sidebar.items.clientMonitorMarketplaceOrderbook'),
          isVisible: isOrderbookEnabled,
        },
        {
          to: 'broker-user.client-monitor.marketplace-depth-of-book',
          title: i18n.t('sidebar.items.clientMonitorMarketplaceDepthOfBook'),
          isVisible: isOrderbookEnabled,
        },
        {
          to: 'broker-user.client-monitor.open-loans',
          title: i18n.t('sidebar.items.clientMonitorOpenLoans'),
        },
        {
          to: 'broker-user.client-monitor.reports',
          title: i18n.t('sidebar.items.clientMonitorReports'),
        },
      ],
      title: i18n.t('sidebar.items.clientMonitor') as string,
      icon: 'mdi-face-agent',
      role: Role.BrokerUser,
      isVisible: true,
    },
  ];

  return items
    .filter((item) => {
      // don't display any item which has item.isVisible set to false
      return (item.isVisible ?? true) && ls.permissionValidator?.hasPermission(item.role);
    })
    .map((item: SidebarItem): SidebarItem => {
      if (item.kind === 'group') {
        return {
          ...item,
          // don't display any children which have c.isVisible set to false
          children: item.children.filter((c) => c.isVisible ?? true) as SidebarGroupChildren,
        };
      }
      return item;
    });
}
